<template>
  <div class="container-login">
    <div class="container-white">
      <div class="text-right q-mr-sm q-mt-sm">
        <LanguageSwitcher />
      </div>
      <div>
        <!-- :src="settings.logo" -->
        <img class="q-ma-lg img-login"  :src="settings.logo ? settings.logo : defaultImageApp"/>
        <h4 class="q-mb-lg q-mt-none">{{ $t("Login.login") }}</h4>
      </div>
      <div class="items-center text-center q-pa-md">
        
        <!-- Credenciales incorrectas -->
        <div v-show="wrongCredentials" class="wrong-credentials q-mb-lg">
          <p class="q-ma-none q-pa-none text-weight-bold">
            {{ $t("Login.wrong-credentials") }}
          </p>
          <p >{{ $t("Login.wrong-credentials-text") }}</p>
        </div>

        <!-- Formulario Login -->
        <q-form class="q-gutter-md" @submit.prevent="handleLogin">
          <div
            class="text-subtitle2 text-left text-weight-bold q-ml-xl q-pl-md q-mr-md q-pa-none"
          >
            {{ $t("Login.mail") }}
          </div>
          <q-input
            name="email"
            rounded
            outlined
            v-model="userForm.email"
            :dense="dense"
            :placeholder="$t('Login.your-mail')"
            class="q-ml-xl q-mr-xl q-pl-md q-mr-md"
          />
          <div
            class="text-subtitle2 text-left text-weight-bold q-ml-xl q-pa-none q-pl-md q-mr-md"
          >
            {{ $t("Login.password") }}
          </div>
          <q-input
            name="password"
            rounded
            outlined
            v-model="userForm.password"
            :type="isPwd ? 'password' : 'text'"
            :dense="dense"
            :placeholder="$t('Login.your-password')"
            class="q-ml-xl q-mr-xl q-pl-md q-mr-md"
            autocomplete="on"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
          <div class="actions">
            <ButtonBlue type="submit"
              >{{ $t("Login.login-title") }}
              <q-spinner
                v-if="loading"
                size="20px"
                style="position: absolute; right: 26px"
              />
            </ButtonBlue>
            <router-link class="forgot-password q-ma-sm q-mt-md cursor-pointer" to="forgot-password" style="font-size: 12px">
              {{ $t("Login.forgotten-password") }}
            </router-link>
          </div>
        </q-form>
      </div>
    </div>
    <div class="q-mt-lg q-pb-lg">
      <!-- <p>{{ $t("Login.have-account") }}</p> -->
      <ButtonBlue to="/signup">{{ $t("Login.register") }} </ButtonBlue>
      <FooterPolitics />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";
import LoginMixin from "../mixins/LoginMixin";
import settings from "@/mixins/settings";

const LanguageSwitcher = defineAsyncComponent(() =>
  import("../../shared/components/LanguageSwitcher.vue")
);

const ButtonBlue = defineAsyncComponent(() =>
  import("../../shared/buttons/ButtonBlue.vue")
);

const FooterPolitics = defineAsyncComponent(() =>
  import("../components/FooterPolitics.vue")
);

export default {
  mixins: [LoginMixin, settings],
  name: "Login",
  components: {
    ButtonBlue,
    FooterPolitics,
    LanguageSwitcher,
  },
  data() {
    return {
      wrongCredentials: false,
      loading: false,
    };
  },
  setup() {
    const userForm = ref({
      email: "",
      password: "",
      remember: true,
    });

    return {
      userForm,
      password: ref(""),
      isPwd: ref(true),
      email: ref(""),
      search: ref(""),
      tel: ref(""),
      url: ref(""),
      time: ref(""),
      date: ref(""),
      dense: ref(""),
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({ signIn: "auth/login" }),
  }
};
</script>

<style lang="scss" scoped>
.img-login {
  width: 150px;
}

h4 {
  font-weight: 700;
  font-size: 32px;
}

.container-login {
  background-color: var(--bg-color-primary);
  height: 100%;
  .container-white {
    background-color: var(--bg-color-secondary);
    border-radius: 0px 0px 42% 42%;
    box-shadow: 0px 8px 16px 0px #00000033;
    border-bottom-left-radius: 50% 60% !important;
    border-bottom-right-radius: 50% 60% !important;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    margin-left: 25%;
    margin-right: 25%;
    h4 {
      font-size: 28px;
    }
  }
  .actions{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (min-width: 767px) {
    .text-subtitle2,
    .q-input {
      padding-left: 13%;
      padding-right: 13%;
    }
  }

  @media only screen and (max-width: 767px) {
    .container-white {
      margin-left: 0%;
      margin-right: 0%;
      border-bottom-left-radius: 50% 38% !important;
      border-bottom-right-radius: 50% 38% !important;
    }

    
  }

  @media (min-width: 768px) and (max-width: 820px) {
    .container-white {
      margin-left: 10%;
      margin-right: 10%;
      border-radius: 0px 0px 40% 40%;
      .items-center {
        padding: 25px 10px;
      }
    }
    .q-form {
      margin-right: 17%;
      margin-left: 17%;
    }
  }

  @media (min-width: 820px) and (max-width: 1020px) {
    .container-white {
      margin-left: 20%;
      margin-right: 20%;
      border-radius: 0px 0px 40% 40%;
      .items-center {
        padding: 25px 10px;
      }
    }
  }

  .wrong-credentials {
    color: var(elements-color-primary) !important;
  }

  .forgot-password {
    text-decoration: underline;
  }
  .forgot-password:hover {
    font-weight: 600;
  }
}
</style>
